import "./Main.css";
import axios from "axios";
import TableContainer from "../components/TableContainer/TableContainer";
import Graph from "../components/Graph/Graph";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer";
import { useState, useEffect, useRef, useCallback } from "react";
import { BlockFragment } from "../generated/graphql";
import { useDebounce } from "hooks/useDebounce";
import { BlockFragment as devBlockFragment } from "../generated/graphql";
import { useNetworkState, useDevNetworkState } from "../hooks/useNetworkState";
import { useArray } from "../hooks/useArray";
import useWindowSize from "../hooks/useWindowSize";
import AOS from "aos";
import handleWarningBarText from "utils/handleWarningBarText";
import "aos/dist/aos.css";
import {
  REACT_APP_ENABLED_NETWORKS,
  TOTAL_L1_TX,
  TOTAL_CWEB_TX,
  TOTAL_TOKENS,
  REACT_APP_LIVENESS_ENDPOINT,
  REACT_APP_DEVNET,
} from "../conf";

function Main() {
  // Client screen width
  const width = useWindowSize();
  // Boolean state for Navbar layout on sroll
  const [show, setShow] = useState(true);
  // State to store if Navbar is open at mobile screen
  const [navOpen, setNavOpen] = useState(false);
  // rowsize for aggregated All chain
  const [rowSize, setRowSize] = useState(10);
  //rowsize array for each network table
  const [networkFetchSizes, setNetworkFetchSizes] = useState(
    new Array(REACT_APP_ENABLED_NETWORKS.length).fill(10)
  );
  const devnet = REACT_APP_DEVNET === "true";
  const totalL1Tx: number = +TOTAL_L1_TX;
  const totalCwebTx: number = +TOTAL_CWEB_TX;
  const totalTokens: number = +TOTAL_TOKENS;
  const [activeMetamask, setActiveMetamask] = useState(false);
  const [subscription, setSubscription] = useState<boolean | undefined>(true);
  const [isReady, setIsReady] = useState<{
    status: boolean;
    networks: string | undefined;
  }>({ status: true, networks: undefined });
  const enableGraph = process.env.REACT_APP_PRICE_GRAPH_ENABLED === "true";
  let availableNetworksNum: number = 0;
  let coinwebTxVol: number = 0;
  let coinwebTxs: number = 0;
  let l1Txs: number = 0;
  const sizeRef: any = useRef(
    Array(REACT_APP_ENABLED_NETWORKS.length).fill(false)
  );

  const handleScroll = useCallback(() => {
    setShow((window as any).pageYOffset <= 30);
  }, []);

  const debouncedHandleScroll = useDebounce(handleScroll, 500);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    // Check if window ethereum is available
    if (typeof (window as any).ethereum !== "undefined") {
      setActiveMetamask(true);
    }
    // Initialize AOS
    AOS.init({ duration: 1000 });
    window.addEventListener("scroll", debouncedHandleScroll); // Add event listener

    // Fetch liveness endpoint data
    if (REACT_APP_LIVENESS_ENDPOINT) {
      axios
        .get(REACT_APP_LIVENESS_ENDPOINT)
        .then((response) => {
          if (isMounted) {
            // Check if component is still mounted before updating state
            if (response.status === 200) {
              setIsReady({ status: true, networks: undefined });
            } else {
              if (response.data && typeof response.data === "string") {
                const match = response.data.match(/\[(.*?)\]/);
                if (match) {
                  const res = handleWarningBarText(match[1] as string);
                  setIsReady({
                    status: false,
                    networks:
                      res && res.slice(0, -2) ? res.slice(0, -2) : undefined,
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching liveness endpoint:", error);
        });
    }

    // Cleanup function to remove event listener when component unmounts
    return () => {
      isMounted = false; // Set flag to false to indicate component unmount
      window.removeEventListener("scroll", handleScroll); // Remove event listener
    };
  }, [debouncedHandleScroll, handleScroll]);

  const AggregateData = () => {
    //Sort Function according to time of a block
    function sortFunction(a: BlockFragment, b: BlockFragment) {
      let dateA = new Date(a.time).getTime();
      let dateB = new Date(b.time).getTime();
      return dateA < dateB ? 1 : -1;
    }

    let tmpCoinwebTxVol: number = totalTokens;
    let tmpCoinwebTxs: number = totalCwebTx;
    let tmpL1Txs: number = totalL1Tx;
    let allData: BlockFragment[] = [];
    // Array to specify working chain networks
    const networkState = new Array(REACT_APP_ENABLED_NETWORKS.length).fill(
      null
    );

    for (const [i, net] of REACT_APP_ENABLED_NETWORKS.entries()) {
      const size: number = networkFetchSizes[i];
      // TODO: We can try to unregister the subscription OR
      // disable fetching of aggregated / last transactions when
      // we are looking at a specific tab.

      // This hook is called in a fixed-length loop, so it's ok.
      let queryResult;
      if (!devnet) {
        /* eslint-disable react-hooks/rules-of-hooks */
        queryResult = useNetworkState(net, size, subscription);
        /* eslint-enable react-hooks/rules-of-hooks */
        if (
          queryResult !== undefined &&
          queryResult &&
          queryResult !== "loading" &&
          queryResult !== "error"
        ) {
          sizeRef.current[i] = true;
          networkState[i] = queryResult;
          const allBlock = queryResult.lastBlocks[0];
          allData = [...allData, allBlock];
          availableNetworksNum++;
          tmpCoinwebTxVol += queryResult.aggregations.totalAmountTransactedCweb;
          tmpCoinwebTxs += queryResult.aggregations.totalNumberOfCwebTxs;
          tmpL1Txs += queryResult.aggregations.totalNumberOfL1Txs;
        } else if (queryResult === "loading") {
          networkState[i] = queryResult;
        }
      } else {
        /* eslint-disable react-hooks/rules-of-hooks */
        queryResult = useDevNetworkState(net, size, subscription);

        if (
          queryResult !== undefined &&
          queryResult &&
          queryResult !== "loading" &&
          queryResult !== "error"
        ) {
          sizeRef.current[i] = true;
          networkState[i] = queryResult;
          const allBlock = queryResult.lastBlocks[0] as devBlockFragment;
          allData = [...allData, allBlock];
          availableNetworksNum++;
          tmpCoinwebTxVol += queryResult.aggregations.totalAmountTransactedCweb;
          tmpCoinwebTxs += queryResult.aggregations.totalNumberOfCwebTxs;
          tmpL1Txs += queryResult.aggregations.totalNumberOfL1Txs;
        } else if (queryResult === "loading") {
          networkState[i] = queryResult;
        } else if (queryResult === undefined) {
          sizeRef.current[i] = true;
        }
      }
    }

    coinwebTxVol = Math.floor(tmpCoinwebTxVol / 1150); //starpoints to dolar conversion //current yen to dollar 1 JPY = 10 Starpoints 1USD = 115 JPY
    coinwebTxs = tmpCoinwebTxs;
    l1Txs = tmpL1Txs;

    allData.sort(sortFunction);
    const memoizedNetworkState = useArray(networkState);
    return [allData, memoizedNetworkState, sizeRef.current];
  };

  const [data, networkState, availableTabs] = AggregateData();
  return (
    <div id="App">
      <div id="App-container">
        {!isReady.status && isReady.networks && (
          <div id="butter-bar">
            <p style={{ marginTop: "5px", marginBottom: "5px", color: "#fff" }}>
              Connection issue at connecting {isReady.networks} network
              {isReady.networks && isReady.networks?.split(",").length - 1 > 0
                ? "s"
                : undefined}
              .
            </p>
          </div>
        )}

        <header className="App-header">
          {width < 1050 ? <></> : <div id="Background"></div>}
          <NavBar setOpen={setNavOpen} show={show} width={width}></NavBar>
        </header>

        <main>
          <div
            style={{
              position: navOpen ? "absolute" : undefined,
              marginTop: width < 1050 ? "0px" : "-250px",
            }}
          >
            {enableGraph ? <Graph width={width}></Graph> : undefined}
            <TableContainer
              cwTxVol={coinwebTxVol}
              cwTxs={coinwebTxs}
              l1Txs={l1Txs}
              setRowsize={setRowSize}
              rowsize={rowSize}
              networkNums={availableNetworksNum}
              networkFetchSizes={networkFetchSizes}
              setNetworkFetchSizes={setNetworkFetchSizes}
              tabs={networkState}
              availableTabs={availableTabs}
              data={data}
              activeMetamask={activeMetamask}
              width={width}
              onSetSubscription={setSubscription}
            />
          </div>
        </main>
        <footer>
          <Footer width={width}></Footer>
        </footer>
      </div>
    </div>
  );
}

export default Main;

import styled from "@emotion/styled";
import { forwardRef, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { findAlias, processTxId, ProcessTxIdProperties } from "utils/processTxId";
import DoneAllIcon from '@mui/icons-material/DoneAll';

type TAliasProperties = {
    item: string;
    alias?: never;
    options?: ProcessTxIdProperties
} | {
    alias: string;
    item?: never;
    options?: ProcessTxIdProperties
}

const Alias = forwardRef<HTMLButtonElement, TAliasProperties>(({item, alias: initialAlias, options, ...rest}) => {
    const [, setSearchParams] = useSearchParams();

    const alias = useMemo(() => item ? findAlias(item) : initialAlias, [item, initialAlias]);

    const processedId = useMemo(() => processTxId(item, options), [item, options])

    const onClick = useCallback(() => {
        if (alias || processedId.text) {
            setSearchParams({ search: alias ?? processedId.text })
        }
    }, [alias, processedId, setSearchParams]);

    return alias 
            ? <StyledButton 
                {...rest} 
                onClick={onClick}
                >
                    {alias}
                    <DoneAllIcon sx={{color: "#00a186"}} fontSize="inherit"/>
              </StyledButton> 
            : <>
                {typeof processedId === 'string' ? processedId : processedId.text}
              </>
})

const StyledButton = styled.button`
    background: #EAF5F3;
    border: 1px solid #00a186;
    border-radius: 4px;
    color: #00a186;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: auto;

    padding: 4px 6px;
`;

export { Alias }
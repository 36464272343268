import "./L2TxLogCard.css";
import { memo, useCallback, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Fade, Tooltip } from "@mui/material";
import { LegacyCwebTxLoadedGql } from "../../generated/graphql";
import {
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import CopyIcon from "components/CopyIcon";
import { processTxId } from "utils/processTxId";
import { NetworkName } from "generated/graphql-devnet";


type LegacyCwebTxLoadedSectionProps = {
  data: LegacyCwebTxLoadedGql;
  width: any;
  network: NetworkName
};

const LegacyCwebTxLoadedSection = (props: LegacyCwebTxLoadedSectionProps) => {
  const { data, width, network } = props;

  const handleAmount = (amountNum: number) => {
    if (amountNum) {
      const value = amountNum / 1150;
      const decimals = 2;
      const amount = "USD " + value.toFixed(decimals);
      return amount;
    } else return "N/A";
  };

  const l1tx = useMemo(() => {
    return processTxId(data.l1Txid, { type: 'l1', shouldSlice: true, network: network })
  }, [data.l1Txid, network]);


  const openNewTab = useCallback(() => {

    if (!l1tx.link) return;

    window.open(l1tx.link, '__blank');

  }, [l1tx])

  return (
    <div
      className={
        width > MOBILE_WIDTH
          ? "L2TxLogCard-data-table"
          : "L2TxLogCard-data-table-mobile"
      }
    >
      <div>
        <Typography className="BoldFont16" align="left">
          L1 Parent ID
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center" gutterBottom>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={<div id="TxId-tooltip">{l1tx.initial}</div>}
            >
              <div onClick={openNewTab} style={{cursor: l1tx.link ? 'pointer' : 'auto'}}>
                {l1tx.text}
              </div>
            </Tooltip>
          </Typography>
          <CopyIcon data={l1tx.initial ?? ''} />
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Amount (USD)
        </Typography>
        <Typography className="BoldFont14" align="center" gutterBottom>
          {handleAmount(Number(data.amount))}
        </Typography>
      </div>
    </div>
  );
};
export default memo(LegacyCwebTxLoadedSection);
